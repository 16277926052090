/**
 * adds resets for buttons that are not covered by reset-and-normalize base
 */

 [role="button"],
 input[type="button"],
 input[type="reset"],
 input[type="submit"],
 button {
   padding: 0;
   border: 0;
   font: inherit;
   font-size: 100%;
   color: inherit;
   text-align: inherit;
   background: none;
   appearance: button;
   user-select: none;
 
   &:not(:disabled) {
     cursor: pointer;
   }
 
   &:-moz-focusring {
     outline: none;
   }
 
   &:focus {
     outline: none;
   }
 
 }