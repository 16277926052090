@import "fonts";
@import "typo";

:root {
  scroll-behavior: smooth;
}

body {
  background-color: $purple-40;
  font-family: 'Neue Machina';
  overflow-x: hidden;
}

.container {
  max-width: 1440px;
  width: calc(100% - 240px);
  margin: 0 auto;
  
  @each $breakpoint, $width in $container-width {
    @include max-width($breakpoint) {
      width: $width;
    }
  }
}