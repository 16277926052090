/// setting up variables, maps and other stuff

$header-height: 100px;

$container-width: (
  'desktop-lg': 1200px,
  'desktop': 960px,
  'tablet': 740px,
  'mobile': 345px
);

$breakpoints: (
  'wide': 1600px,
  'desktop-lg': 1440px,
  'desktop': 1280px,
  'tablet': 1024px,
  'mobile': 768px,
  'xs': 375px
);
