/**
 * adds resets for links that are not covered by reset-and-normalize base
 */

 a {
  color: inherit;
  text-decoration: none;

  &:-moz-focusring {
    outline:none;
  }

  &:focus {
    outline: none;
  }

}