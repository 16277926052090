.decor {
    max-width: 1440px;
    margin: 0 auto;
  }
  
  .decor-1 {
    position: relative;
  
    @include min-width(mobile) {
      background: transparent;
    }
  
    &__lighter {
      position: absolute;
      bottom: 0;
      transform: translate(-70%, 80%);
  
      @include max-width(tablet) {
        width: 790px;
        height: 660px;
        transform: translate(-35%, 0%);
      }
    }
  
    &__wandering-line {
      position: absolute;
      left: 25px;
      top: 0;
      transform: rotate(-90deg) translateX(calc(50% - 4px)) translateY(-16px);
      transform-origin: top;
      &::after {
        content: "";
        background: linear-gradient(rgba(248, 248, 248, 0.22), rgba(255, 255, 255, 0.15));
        position: absolute;
        width: 4px;
        height: 100vw;
        z-index: -1;
      }
  
      .dot {
        width: 8px;
        height: 8px;
        &:first-child {
          width: 16px;
          height: 16px;
          margin-bottom: 230px;
        }
      }
  
      @include min-width(mobile) {
        display: none;
      }
    }
  }
  
  .decor-2 {
    position: relative;
  
    &__lighter {
      width: 681px;
      height: 681px;
      position: absolute;
      top: 0;
      transform: translate(-13%, -20%);
  
      @include max-width(tablet) {
        width: 144px;
        height: 159px;
        transform: translate(-20%, -30%);
      }
    }
  }
  
  .decor-3 {
    position: relative;
  
    &__lighter {
      width: 535px;
      height: 535px;
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translate(80%, 29%);
  
      @include max-width(tablet) {
        width: 565px;
        height: 964px;
        transform: translate(70%, -5%);
      }
    }
  }
  
  .decor-4 {
    position: relative;
  
    &__lighter {
      width: 270px;
      height: 764px;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(69%, 1%);
      display: none;
      @include max-width(tablet) {
        display: block;
      }
    }
  }
  
  .decor-5 {
    position: relative;
  
    &__lighter {
      width: 646px;
      height: 646px;
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translate(-73%, -25%);
  
      @include max-width(tablet) {
        width: 865px;
        height: 548px;
        transform: translate(-50%, -32%);
      }
    }
  }