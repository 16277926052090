.contact {

  margin-bottom: 164px;
  position: relative;

  @include max-width(tablet) {
    margin-bottom: 265px;
  }

  &__lighter {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-110%, -85%);

    @include max-width(tablet) {
      transform: translate(-55%, -15%);
      width: 252px;
      height: 548px;
    }
  }

  &__content {
    display: flex;
    margin-bottom: 102px;
    padding-left: 35px;

    @include max-width(tablet) {
      padding-left: unset;
    }

    @include max-width(mobile) {
      margin-bottom: 64px;
      flex-direction: column;
    }
  }

  &__heading {
    margin-right: 112px;
    line-height: 96.5%;

    @include max-width(tablet) {
      margin-bottom: 55px;
    }
    @include max-width(mobile) {
      margin-right: unset;
    }
  }

  &__text {
    max-width: 670px;
    font-family: Inter;
    padding-top: 9px;
    line-height: 120%;
  }

  &__cta {
    margin-left: auto;
  }
}
