.footer-menu {
  &__title {
    margin-bottom: 32px;
    font-weight: 700;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    font-weight: 400;
  }
}