$purple-40: #0C000C;
$Purple-30: #9C9C9C;
$purple-20: #332833;
$purple-10: #996AFF;
$grey-30: #5A5A5A;
$grey-20: #9C9C9C;
$grey-10: #FCFCFC;
$blue-10: #33CEFF;
$coral-10: #33CEFF;
$white: #FFFFFF;
$black: #000000;