.advantages {
  margin-bottom: 250px;
  display: grid;
  grid-template-areas:
    "advantages-heading advantages-cta"
    "advantages-wall advantages-wall";
  row-gap: 60px;
  align-items: center;

  @include max-width(tablet) {
    grid-template-areas:
      "advantages-heading"
      "advantages-wall"
      "advantages-cta";

    margin-bottom: 140px;
    gap: unset;
  }

  &__heading {
    grid-area: advantages-heading;

    @include max-width(tablet) {
      margin-bottom: 48px;
    }
  }

  &__cta {
    grid-area: advantages-cta;
    margin-left: auto;

    @include max-width(tablet) {
      margin-left: unset;
    }
  }

  &__wall {
    grid-area: advantages-wall;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    @include max-width(desktop) {
      flex-direction: column;
      // align-items: center;
    }
    @include max-width(tablet) {
      margin-bottom: 32px;
    }
  }

  &__card {
    flex: 1;
    @include max-width(desktop) {
      flex: unset;
      //   width: 385px;
    }
  }
}
