@mixin font-face($font-family, $name, $weight) {
  @font-face {
    font-family: '#{$font-family}';
    src: url('/assets/fonts/#{$name}.woff') format('woff');
    font-weight: #{$weight};
    font-display: swap;
  }
}

@mixin min-width($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: #{map-get($breakpoints, $breakpoint)}) {
      @content;
    }
  }

  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. " + "Please make sure it is defined in `#{$breakpoints}` map.";
  }
}

@mixin max-width($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (max-width: #{map-get($breakpoints, $breakpoint) - 1}) {
      @content;
    }
  }

  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. " + "Please make sure it is defined in `#{$breakpoints}` map.";
  }
}

// @mixin lighter-position($top, $right, $bottom, $left, $trX, $trY) {

// }