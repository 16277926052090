.adv-card {
  background-color: #251a25;
  border-radius: 16px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: relative;
  height: 415px;
  overflow: hidden;
  
  @include max-width(mobile) {
    flex: unset;
    width: 100%;
  }

  &__image {
    transform: translate(20%, 20%);
    bottom: 0;
    right: 0;
    position: absolute;
    object-fit: contain;
    max-width: 100%;
    height: 280px;
  }

  &__text {
    font-family: Inter;
  }
}
