.partner-label {
  height: 120px;
  padding: 26px 52px;
  background-color: $grey-10;
  border-radius: 16px;

  @include max-width(tablet) {
    height: 52px;
    border-radius: 7px;
    padding: 10px 20px;
  }
  // &__wrap {
    display: flex;
    align-items: center;
    justify-content: center;
  // }

  &__image {
    display: flex;
    flex: 1;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}
