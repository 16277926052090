/**
 * Based on
 *
 *  - reset.css 2.0 by Eric Meyer
      (public domain)
 *    http://meyerweb.com/eric/tools/css/reset/
 *
 *  - normalize.css 8.0.1 by Nicolas Gallagher and Jonathan Neal
 *    (licensed under MIT)
 *    https://github.com/necolas/normalize.css
 *
 *  - Reboot from Bootstrap 4.5.3
 *    (licensed under MIT)
 *    https://github.com/twbs/bootstrap
 */

/**
 * IE10+ doesn't honor `<meta name="viewport">` in some cases
 */

@-ms-viewport {
  width: device-width;
}

/**
 * general reset
 */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
main {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/**
 * HTML5 display-role reset for older browsers
 */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main,
summary {
  display: block;
}

/**
 * inherit box model for all elements
 */

*,
*::before,
*::after {
  box-sizing: inherit;
}

/**
 * html root rules
 * 1. set border-box for inheritance
 * 2. avoid 300ms click delay on touch devices that support the `touch-action`
 *    CSS property
 * 3. Prevent adjustments of font size after orientation changes in IE, on
 *    Windows Phone and iOS.
 * 4. Setting @viewport causes scrollbars to overlap content in IE11 and Edge,
 *    so we force a non-overlapping, non-auto-hiding scrollbar to counteract.
 * 5. Change the default tap highlight to be completely transparent in iOS.
 */

html {
  /* 1 */
  box-sizing: border-box;
  /* 2 */
  touch-action: manipulation;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  /* 4 */
  -ms-overflow-style: scrollbar;
  /* 5 */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/**
 * body rules
 * 1. reset line-height to 1
 * 2. set base font-family to sans-serif
 * 3. Set an explicit initial text-align value so that we can later use the
 *    `inherit` value on things like `<th>` elements.
 */

body {
  /* 1 */
  line-height: 1;
  /* 2 */
  font-family: sans-serif;
  /* 3 */
  text-align: left;
}

/**
 * Future-proof rule: in browsers that support :focus-visible, suppress the focus outline
 * on elements that programmatically receive focus but wouldn't normally show a visible
 * focus outline. In general, this would mean that the outline is only applied if the
 * interaction that led to the element receiving programmatic focus was a keyboard interaction,
 * or the browser has somehow determined that the user is primarily a keyboard user and/or
 * wants focus outlines to always be presented.
 *
 * See https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible
 * and https://developer.paciellogroup.com/blog/2018/03/focus-visible-and-backwards-compatibility/
 */

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

/**
 * Lists
 */

ol,
ul {
  list-style: none;
}

/**
 * Quotes
 */

blockquote,
q {
  quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: "";
  content: none;
}

/**
 * Tables
 */

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption {
  caption-side: bottom;
}

/**
 * Table Headers
 * 1. Matches default `<td>` alignment by inheriting from the `<body>`, or the
 *    closest parent with a set `text-align`.
 * 2. Fix alignment for Safari
 */

th {
  /* 1 */
  text-align: inherit;
  /* 2 */
  text-align: -webkit-match-parent;
}

/**
 * Horizontal Lines
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */

hr {
  /* 1 */
  box-sizing: content-box;
  height: 0;
  /* 2 */
  overflow: visible;
}

/**
 * Preformatted Text
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Don't allow content to break outside
 * 3. We have @viewport set which causes scrollbars to overlap content in IE11
 *    and Edge, so we force a non-overlapping, non-auto-hiding scrollbar to
 *    counteract.
 */

pre,
code,
kbd,
samp {
  /* 1 */
  font-family: monospace, monospace;
}

pre {
  /* 2 */
  overflow: auto;
  /* 3 */
  -ms-overflow-style: scrollbar;
}

/**
 * Links
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
 */

a {
  /* 1 */
  background-color: transparent;
  /* 2 */
  -webkit-text-decoration-skip: objects;
}

/**
 * 1. Remove the bottom border in Chrome 57- and Firefox 39-
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 * 3. Add explicit cursor to indicate changed behavior.
 * 4. Prevent the text-decoration to be skipped.
 */

abbr[title] {
  /* 1 */
  border-bottom: 0;
  /* 2 */
  text-decoration: underline;
  text-decoration: underline dotted;
  /* 3 */
  cursor: help;
  /* 4 */
  text-decoration-skip-ink: none;
}

address {
  font-style: normal;
  line-height: inherit;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */

b,
strong {
  font-weight: bolder;
}

/**
 * Add the correct font size in all browsers.
 */

small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/**
 * Prevent `em` being affected from global reset
 */

em {
  font-style: italic;
}

/**
 * Remove the border on images inside links in IE 10.
 */

img {
  border-style: none;
}

/**
 * Hide SVG overflow in IE
 */

svg:not(:root) {
  overflow: hidden;
}

/**
 * Remove the default `border-radius` that macOS Chrome adds.
 * Details at https://github.com/twbs/bootstrap/issues/24093
 */

button {
  border-radius: 0;
}

/**
 * Work around a Firefox/IE bug where the transparent `button` background
 * results in a loss of the default `button` focus styles.
 * Credit: https://github.com/suitcss/base/
 */

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

/**
 * form element resets
 * 1. Remove the margin in Firefox and Safari
 * 2. inherit font rules
 */

input,
button,
select,
optgroup,
textarea {
  /* 1 */
  margin: 0;
  /* 2 */
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */

button,
[type=reset],
[type=submit],
[type=button] {
  /* 2 */
  -webkit-appearance: button;
}

/**
 * Remove the default appearance of temporal inputs to avoid a Mobile Safari
 * bug where setting a custom line-height prevents text from being vertically
 * centered within the input.
 * See https://bugs.webkit.org/show_bug.cgi?id=139848
 * and https://github.com/twbs/bootstrap/issues/11266
 */

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
  -webkit-appearance: listbox;
}

/**
 * 1. Remove the default vertical scrollbar in IE.
 * 2. Textareas should really only resize vertically so they don't break their
 *    (horizontal) containers.
 */

textarea {
  overflow: auto;
  resize: vertical;
}

/**
 * Show the overflow in IE.
 */

button,
input {
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 */

button,
select {
  text-transform: none;
}

/**
 * Set the cursor for non-`<button>` buttons
 * Details at https://github.com/twbs/bootstrap/pull/30562
 */

[role=button] {
  cursor: pointer;
}

/**
 * Remove the inheritance of word-wrap in Safari.
 * See https://github.com/twbs/bootstrap/issues/24990
 */

select {
  word-wrap: normal;
}

/**
 * Remove inner border and padding from Firefox, but don't restore the outline
 * like Normalize.
 */

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * 1. Add the correct box sizing in IE 10-
 * 2. Remove the padding in IE 10-
 */

input[type=radio],
input[type=checkbox] {
  /* 1 */
  box-sizing: border-box;
  /* 2 */
  padding: 0;
}

/**
 * Suppress the focus outline on elements that cannot be accessed via keyboard.
 * This prevents an unwanted focus outline from appearing around elements that
 * might still respond to pointer events.
 * Credit: https://github.com/suitcss/base
 */

[tabindex="-1"]:focus {
  outline: 0 !important;
}

/**
 * Browsers set a default `min-width: min-content` on fieldsets,
 * unlike e.g. `<div>`s, which have `min-width: 0` by default.
 * So we reset that to ensure fieldsets behave more like a standard block element.
 * See https://github.com/twbs/bootstrap/issues/12359
 * and https://html.spec.whatwg.org/multipage/#the-fieldset-and-legend-elements
 */

fieldset {
  min-width: 0;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Set display to block for all browsers
 */

legend {
  /* 1 */
  max-width: 100%;
  white-space: normal;
  /* 2 */
  color: inherit;
  /* 3 */
  display: block;
}

/**
 * Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */

progress {
  vertical-align: baseline;
}

/**
 * 1. Remove the default vertical scrollbar in IE 10+.
 * 2. Textareas should really only resize vertically so they don't break their
 *    (horizontal) containers.
 */

textarea {
  /* 1 */
  overflow: auto;
  /* 2 */
  resize: vertical;
}

/**
 * 1. Add the correct box sizing in IE 10.
 * 2. Remove the padding in IE 10.
 */

[type=checkbox],
[type=radio] {
  /* 1 */
  box-sizing: border-box;
  /* 2 */
  padding: 0;
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */

[type=search] {
  /* 1 */
  -webkit-appearance: textfield;
  /* 2 */
  outline-offset: -2px;
}

/**
 * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
 */

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */

::-webkit-file-upload-button {
  /* 1 */
  -webkit-appearance: button;
  /* 2 */
  font: inherit;
}

/**
 * Correct element display for output
 */

output {
  display: inline-block;
}

/**
 * Add the correct display in IE 10+.
 */

template {
  display: none;
}

/**
 * Always hide an element with the `hidden` HTML attribute (from PureCSS).
 * Needed for proper display in IE 10-.
 */

[hidden] {
  display: none;
}

/**
 * adds resets for buttons that are not covered by reset-and-normalize base
 */

[role=button],
input[type=button],
input[type=reset],
input[type=submit],
button {
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  color: inherit;
  text-align: inherit;
  background: none;
  appearance: button;
  user-select: none;
}

[role=button]:not(:disabled),
input[type=button]:not(:disabled),
input[type=reset]:not(:disabled),
input[type=submit]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}

[role=button]:-moz-focusring,
input[type=button]:-moz-focusring,
input[type=reset]:-moz-focusring,
input[type=submit]:-moz-focusring,
button:-moz-focusring {
  outline: none;
}

[role=button]:focus,
input[type=button]:focus,
input[type=reset]:focus,
input[type=submit]:focus,
button:focus {
  outline: none;
}

/**
 * adds resets for links that are not covered by reset-and-normalize base
 */

a {
  color: inherit;
  text-decoration: none;
}

a:-moz-focusring {
  outline: none;
}

a:focus {
  outline: none;
}

@keyframes splide-loading {
  0% {
    transform: rotateZ(0);
  }

  100% {
    transform: rotateZ(360deg);
  }
}

.splide__track--draggable {
  -webkit-touch-callout: none;
  user-select: none;
}

.splide__track--fade > .splide__list > .splide__slide {
  margin: 0 !important;
  opacity: 0;
  z-index: 0;
}

.splide__track--fade > .splide__list > .splide__slide.is-active {
  opacity: 1;
  z-index: 1;
}

.splide--rtl {
  direction: rtl;
}

.splide__track--ttb > .splide__list {
  display: block;
}

.splide__container {
  box-sizing: border-box;
  position: relative;
}

.splide__list {
  backface-visibility: hidden;
  display: flex;
  height: 100%;
  margin: 0 !important;
  padding: 0 !important;
}

.splide.is-initialized:not(.is-active) .splide__list {
  display: block;
}

.splide__pagination {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  pointer-events: none;
}

.splide__pagination li {
  display: inline-block;
  line-height: 1;
  list-style-type: none;
  margin: 0;
  pointer-events: auto;
}

.splide:not(.is-overflow) .splide__pagination {
  display: none;
}

.splide__progress__bar {
  width: 0;
}

.splide {
  position: relative;
  visibility: hidden;
}

.splide.is-initialized,
.splide.is-rendered {
  visibility: visible;
}

.splide__slide {
  backface-visibility: hidden;
  box-sizing: border-box;
  flex-shrink: 0;
  list-style-type: none !important;
  margin: 0;
  position: relative;
}

.splide__slide img {
  vertical-align: bottom;
}

.splide__spinner {
  animation: splide-loading 1s infinite linear;
  border: 2px solid #999;
  border-left-color: transparent;
  border-radius: 50%;
  bottom: 0;
  contain: strict;
  display: inline-block;
  height: 20px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
}

.splide__sr {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.splide__toggle__pause {
  display: none;
}

.splide__toggle.is-active .splide__toggle__play {
  display: none;
}

.splide__toggle.is-active .splide__toggle__pause {
  display: inline;
}

.splide__track {
  overflow: hidden;
  position: relative;
  z-index: 0;
}

@font-face {
  font-family: "Neue Machina";
  src: url("/assets/fonts/NeueMachina-Bold.woff") format("woff");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "Neue Machina";
  src: url("/assets/fonts/NeueMachina-Medium.woff") format("woff");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Neue Machina";
  src: url("/assets/fonts/NeueMachina-Regular.woff") format("woff");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("/assets/fonts/Inter-Bold.woff") format("woff");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("/assets/fonts/Inter-Medium.woff") format("woff");
  font-weight: 500;
  font-display: swap;
}

.heading {
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  color: #FCFCFC;
}

.heading--section {
  font-size: 48px;
}

.heading--3 {
  font-size: 32px;
}

.heading--4 {
  font-size: 24px;
}

.heading--5 {
  font-size: 22px;
}

.text {
  font-weight: 500;
  line-height: 150%;
  color: #FCFCFC;
}

.text--muted {
  color: #9C9C9C;
}

.text--14 {
  font-size: 14px;
}

.text--16 {
  font-size: 16px;
}

.text--20 {
  font-size: 20px;
}

:root {
  scroll-behavior: smooth;
}

body {
  background-color: #0C000C;
  font-family: "Neue Machina";
  overflow-x: hidden;
}

.container {
  max-width: 1440px;
  width: calc(100% - 240px);
  margin: 0 auto;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 17px;
  border: 1px solid;
  border-radius: 14px;
  background-color: transparent;
  transition: all 0.3s ease;
  height: 56px;
  padding: 0 25px;
  cursor: pointer;
  width: fit-content;
  text-transform: uppercase;
  font-family: "Inter";
  font-weight: 700;
  font-size: 16px;
}

.button--white {
  color: #FCFCFC;
  border-color: #FCFCFC;
}

.button--white:hover {
  color: #0C000C;
  background-color: #FCFCFC;
}

.button--purple {
  color: #996AFF;
  border-color: #996AFF;
}

.button--purple:hover {
  color: #FCFCFC;
  background-color: #996AFF;
}

.button__icon {
  display: flex;
}

.square-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
  background-color: #996AFF;
  color: #FCFCFC;
  border-radius: 7px;
}

.line {
  width: 4px;
  background: linear-gradient(rgba(248, 248, 248, 0.3019607843), rgba(255, 255, 255, 0.0666666667));
}

.dot {
  transform: translateX(calc(-50% + 2px));
  background-color: #33CEFF;
  border-radius: 9999px;
  width: 14px;
  height: 14px;
  display: block;
}

.dot--purple {
  width: 28px;
  height: 28px;
  background: radial-gradient(50% 50% at 50% 50%, #996AFF 99.99%, rgba(255, 255, 255, 0) 100%);
}

.partner-label {
  height: 120px;
  padding: 26px 52px;
  background-color: #FCFCFC;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.partner-label__image {
  display: flex;
  flex: 1;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.popup {
  background: #0C000C;
  box-shadow: 11px 19px 25px rgba(154, 106, 255, 0.2);
  border-radius: 24px;
  padding: 110px 124px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 790px;
  text-align: center;
  display: flex;
  align-items: center;
  transition: opacity 0.3s ease;
}

.popup__wrap {
  width: 100%;
}

.popup__close {
  cursor: pointer;
  position: absolute;
  top: 32px;
  right: 32px;
}

.popup__heading {
  margin-bottom: 30px;
}

.popup__hint {
  margin-bottom: 40px;
}

.popup__form {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.popup__submit {
  align-self: flex-end;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(46, 46, 46, 0.8352941176);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  transition: opacity 0.3s ease;
}

.textfield {
  background: #FCFCFC;
  border: 1px solid #5A5A5A;
  border-radius: 14px;
  height: 47px;
  font-weight: 500;
  font-size: 14px;
  line-height: 128%;
  font-family: Inter;
  display: flex;
  align-items: center;
  padding: 0 15px;
  outline: none;
}

.textfield::placeholder {
  color: #9C9C9C;
}

.textfield--textarea {
  padding-top: 15px;
  padding-bottom: 15px;
  min-height: 180px;
  resize: none;
}

.lighter {
  background: radial-gradient(50% 50% at 50% 50%, rgba(51, 206, 255, 0.07) 18.02%, rgba(255, 255, 255, 0) 100%);
  border-radius: 100px;
  width: 690px;
  height: 690px;
  pointer-events: none;
}

.header {
  height: 100px;
}

.header__container {
  display: flex;
  align-items: center;
  height: 100%;
  gap: 94px;
}

.header__logo {
  display: flex;
  width: 140px;
  height: 24px;
}

.header__nav {
  margin-right: auto;
  line-height: normal;
  display: flex;
  gap: 40px;
}

.header__call {
  font-family: Inter;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: #FCFCFC;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 17px;
}

.header__icon {
  display: flex;
}

.footer {
  padding-top: 54px;
  padding-bottom: 24px;
  background: #0C000C;
}

.footer__top {
  display: flex;
  justify-content: space-between;
  padding-bottom: 52px;
  border-bottom: 2px solid #FCFCFC;
}

.footer__aside {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer__logo {
  width: 140px;
  height: 24px;
  display: flex;
}

.footer__messengers {
  display: flex;
  gap: 12px;
}

.footer__menues {
  display: flex;
  column-gap: 64px;
}

.footer__menu:first-child {
  grid-area: company;
}

.footer__menu:nth-child(2) {
  grid-area: info;
}

.footer__menu:last-child {
  grid-area: contacts;
}

.footer__bottom {
  padding-top: 28px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 24px;
}

.footer__policy,
.footer__agreement,
.footer__copyright {
  opacity: 0.6;
}

.footer__agreement {
  margin-left: 172px;
}

.footer__copyright {
  display: flex;
  gap: 24px;
  width: 100%;
}

.adv-card {
  background-color: #251a25;
  border-radius: 16px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: relative;
  height: 415px;
  overflow: hidden;
}

.adv-card__image {
  transform: translate(20%, 20%);
  bottom: 0;
  right: 0;
  position: absolute;
  object-fit: contain;
  max-width: 100%;
  height: 280px;
}

.adv-card__text {
  font-family: Inter;
}

.rej-card {
  background-color: rgba(255, 255, 255, 0.0509803922);
  border-radius: 16px;
  padding: 24px;
  min-height: 204px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.rej-card__close {
  margin-bottom: 48px;
  display: block;
}

.rej-card__title {
  line-height: 110%;
  margin-bottom: 12px;
}

.rej-card__text {
  line-height: 24px;
  max-width: 85%;
}

.rej-card__text {
  font-family: Inter;
}

.footer-menu__title {
  margin-bottom: 32px;
  font-weight: 700;
}

.footer-menu__list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-weight: 400;
}

.wandering-line {
  display: flex;
  flex-direction: column;
}

.wandering-line .dot:first-child {
  margin-bottom: 388px;
}

.wandering-line .dot:nth-child(2) {
  margin-bottom: 42px;
}

.wandering-line .dot:nth-child(3) {
  margin-bottom: 244px;
}

.decor {
  max-width: 1440px;
  margin: 0 auto;
}

.decor-1 {
  position: relative;
}

.decor-1__lighter {
  position: absolute;
  bottom: 0;
  transform: translate(-70%, 80%);
}

.decor-1__wandering-line {
  position: absolute;
  left: 25px;
  top: 0;
  transform: rotate(-90deg) translateX(calc(50% - 4px)) translateY(-16px);
  transform-origin: top;
}

.decor-1__wandering-line::after {
  content: "";
  background: linear-gradient(rgba(248, 248, 248, 0.22), rgba(255, 255, 255, 0.15));
  position: absolute;
  width: 4px;
  height: 100vw;
  z-index: -1;
}

.decor-1__wandering-line .dot {
  width: 8px;
  height: 8px;
}

.decor-1__wandering-line .dot:first-child {
  width: 16px;
  height: 16px;
  margin-bottom: 230px;
}

.decor-2 {
  position: relative;
}

.decor-2__lighter {
  width: 681px;
  height: 681px;
  position: absolute;
  top: 0;
  transform: translate(-13%, -20%);
}

.decor-3 {
  position: relative;
}

.decor-3__lighter {
  width: 535px;
  height: 535px;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(80%, 29%);
}

.decor-4 {
  position: relative;
}

.decor-4__lighter {
  width: 270px;
  height: 764px;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(69%, 1%);
  display: none;
}

.decor-5 {
  position: relative;
}

.decor-5__lighter {
  width: 646px;
  height: 646px;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translate(-73%, -25%);
}

.about {
  padding-top: 64px;
  padding-bottom: 200px;
  background: url(/assets/images/main_bg.png) 130% 0/contain no-repeat;
  margin-bottom: 86px;
}

.about__info {
  font-family: Inter;
  font-size: 20px;
  line-height: 110%;
  max-width: 460px;
  margin-bottom: 64px;
}

.about__heading {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 96px;
  line-height: 110%;
}

.about__heading-part {
  color: #996AFF;
}

.about__cta .button__icon {
  display: none;
}

.audience {
  display: flex;
  flex-direction: column;
  gap: 52px;
  margin-bottom: 116px;
  position: relative;
}

.audience__lighter {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-85%, -62%);
}

.audience__heading {
  display: none;
}

.audience__block {
  display: flex;
  gap: 160px;
}

.audience__title {
  flex: 280px 0 0;
  line-height: 96.5%;
}

.audience__text {
  max-width: 700px;
}

.audience__list {
  font-family: Inter;
  max-width: 620px;
}

.advantages {
  margin-bottom: 250px;
  display: grid;
  grid-template-areas: "advantages-heading advantages-cta" "advantages-wall advantages-wall";
  row-gap: 60px;
  align-items: center;
}

.advantages__heading {
  grid-area: advantages-heading;
}

.advantages__cta {
  grid-area: advantages-cta;
  margin-left: auto;
}

.advantages__wall {
  grid-area: advantages-wall;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.advantages__card {
  flex: 1;
}

.flow {
  position: relative;
  margin-bottom: 144px;
}

.flow__heading {
  text-align: center;
  margin-bottom: 136px;
}

.flow__wandering-line {
  display: flex;
  flex-direction: column;
  padding-bottom: 52px;
  position: absolute;
  left: 150px;
  bottom: 480px;
}

.flow__wandering-line .dot:first-child {
  margin-bottom: 388px;
}

.flow__wandering-line .dot:nth-child(2) {
  margin-bottom: 42px;
}

.flow__wandering-line .dot:nth-child(3) {
  margin-bottom: 244px;
}

.flow__content {
  display: flex;
  justify-content: flex-end;
}

.flow__line {
  margin-top: 24px;
}

.flow__list {
  display: flex;
  flex-direction: column;
  gap: 48px;
  max-width: 850px;
  padding-bottom: 72px;
}

.flow__item {
  display: flex;
  padding-left: 80px;
  position: relative;
  cursor: default;
}

.flow__marker {
  position: absolute;
  left: 0;
  top: 8px;
  transform: translate(calc(-50% - 2px), 100%);
  transition: all 0.3s ease-in-out;
}

.flow__title:hover ~ .flow__marker {
  transform: translate(calc(-50% - 2px), 50%) scale(2);
  box-shadow: 0px 4px 68px #9a6aff;
  background-color: #996AFF;
}

.partners {
  margin-bottom: 284px;
}

.partners__heading {
  margin-bottom: 64px;
}

.partners__slider--first {
  margin-bottom: 24px;
}

.faq {
  position: relative;
  overflow: hidden;
  padding-bottom: 276px;
}

.faq__decoration {
  border: 1px solid #332833;
  border-left: none;
  position: absolute;
  top: 300px;
  right: calc(100% - (50% - 600px) - 204px);
  bottom: 196px;
  left: 0;
}

.faq__dot {
  position: absolute;
  top: 268px;
  right: 0;
  transform: translateX(50%);
}

.faq__container {
  position: relative;
}

.faq__heading {
  margin-bottom: 164px;
}

.faq__inner-wrap {
  width: 850px;
  margin-left: auto;
}

.faq__list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.faq__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 68px;
  padding-right: 20px;
  border-bottom: 2px solid #5A5A5A;
}

.faq__title {
  padding: 24px 0;
  line-height: normal;
  cursor: pointer;
}

.faq__icon {
  display: flex;
  cursor: pointer;
  transition: all 0.3s ease;
}

.faq__icon--pressed {
  transform: rotate(45deg);
}

.faq__body {
  max-height: 0;
  overflow: hidden;
  transition: all 0.8s ease-in-out;
}

.faq__body--expanded {
  max-height: 400px;
}

.faq__text {
  padding: 16px 0;
}

.rejection {
  margin-bottom: 176px;
}

.rejection__heading {
  margin-bottom: 72px;
}

.rejection__wall {
  display: grid;
  grid-template-columns: 360px 1fr 360px;
  gap: 32px;
}

.rejection__card--double-span {
  grid-column: span 2;
}

.contact {
  margin-bottom: 164px;
  position: relative;
}

.contact__lighter {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-110%, -85%);
}

.contact__content {
  display: flex;
  margin-bottom: 102px;
  padding-left: 35px;
}

.contact__heading {
  margin-right: 112px;
  line-height: 96.5%;
}

.contact__text {
  max-width: 670px;
  font-family: Inter;
  padding-top: 9px;
  line-height: 120%;
}

.contact__cta {
  margin-left: auto;
}

.services {
  margin-bottom: 186px;
}

.services__container {
  position: relative;
}

.services__lighter {
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(70%, -90%);
}

.services__top {
  margin-bottom: 76px;
}

.services__heading {
  margin-bottom: 8px;
}

.services__list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.services__list--visible {
  margin-bottom: 16px;
}

.services__list--expandable {
  max-height: 0;
  overflow: hidden;
  transition: all 1.2s ease-in-out;
}

.services__list--expanded {
  max-height: 3000px;
}

.services__item {
  position: relative;
  padding-top: 16px;
  min-height: 100px;
  border-bottom: 2px solid #332833;
}

.services__title {
  max-width: calc(100% - 32px - 24px - 80px);
}

.services__icon {
  position: absolute;
  right: 32px;
  top: 32px;
  display: flex;
  color: #996AFF;
}

.services__bottom {
  margin-top: 62px;
}

.services__button {
  margin-left: auto;
}

.page-content {
  max-width: 100vw;
  overflow: hidden;
}

@media (min-width: 768px) {
  .decor-1 {
    background: transparent;
  }

  .decor-1__wandering-line {
    display: none;
  }
}

@media (min-width: 1600px) {
  .about {
    width: calc(100% - 240px);
    max-width: 1440px;
    margin: 0 auto 86px;
    background-position: right;
  }

  .about__container {
    width: 100%;
  }
}

@media (max-width: 1439px) {
  .container {
    width: 1200px;
  }

  .about {
    padding-bottom: 100px;
  }
}

@media (max-width: 1279px) {
  .container {
    width: 960px;
  }

  .popup {
    padding: 100px 32px;
    min-width: 500px;
  }

  .about {
    background-position: right bottom;
    background-size: 650px auto;
  }

  .advantages__wall {
    flex-direction: column;
  }

  .advantages__card {
    flex: unset;
  }

  .flow__wandering-line {
    left: 20px;
  }

  .faq__decoration {
    top: 0;
    right: 0;
    bottom: unset;
    left: unset;
    height: 144px;
    width: 160px;
    border-left: 1px solid #332833;
    border-right: none;
  }

  .faq__dot {
    top: 50%;
    left: 0;
    right: unset;
    bottom: unset;
    transform: translate(-50%, -50%);
  }

  .rejection__wall {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 1023px) {
  .heading--section {
    font-size: 32px;
    line-height: normal;
  }

  .heading--3 {
    font-size: 24px;
    line-height: 110%;
  }

  .text--20 {
    font-size: 14px;
  }

  .container {
    width: 740px;
  }

  .button {
    width: unset;
  }

  .button--purple {
    color: #FCFCFC;
    background-color: #996AFF;
  }

  .partner-label {
    height: 52px;
    border-radius: 7px;
    padding: 10px 20px;
  }

  .header__container {
    justify-content: space-between;
  }

  .header__nav {
    margin-right: unset;
  }

  .header__call {
    display: none;
  }

  .footer {
    padding-top: 24px;
    padding-bottom: 48px;
  }

  .footer__top {
    flex-direction: column;
  }

  .footer__aside {
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 32px;
    margin-bottom: 60px;
  }

  .footer__menues {
    display: grid;
    grid-template-areas: "company info" "company contacts";
  }

  .footer__menu:nth-child(2) {
    padding-bottom: 14px;
    border-bottom: 2px solid #FCFCFC;
    margin-bottom: 14px;
  }

  .footer__bottom {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: 47px;
    row-gap: 32px;
  }

  .footer__agreement {
    margin-left: unset;
  }

  .footer__copyright {
    width: unset;
  }

  .rej-card {
    min-height: unset;
  }

  .rej-card__close {
    margin-bottom: 24px;
  }

  .rej-card__title {
    font-size: 16px;
  }

  .rej-card__text {
    line-height: 150%;
  }

  .decor-1__lighter {
    width: 790px;
    height: 660px;
    transform: translate(-35%, 0%);
  }

  .decor-2__lighter {
    width: 144px;
    height: 159px;
    transform: translate(-20%, -30%);
  }

  .decor-3__lighter {
    width: 565px;
    height: 964px;
    transform: translate(70%, -5%);
  }

  .decor-4__lighter {
    display: block;
  }

  .decor-5__lighter {
    width: 865px;
    height: 548px;
    transform: translate(-50%, -32%);
  }

  .about {
    background-position: right;
    padding: unset;
  }

  .about__heading {
    margin-bottom: 324px;
    font-size: 22px;
  }

  .audience {
    gap: 40px;
    margin-bottom: 148px;
    padding-top: 76px;
  }

  .audience__lighter {
    width: 790px;
    height: 660px;
    transform: translate(-40%, -200%);
  }

  .audience__heading {
    display: block;
  }

  .audience__block {
    flex-direction: column;
    gap: 12px;
  }

  .audience__title {
    flex: unset;
  }

  .advantages {
    grid-template-areas: "advantages-heading" "advantages-wall" "advantages-cta";
    margin-bottom: 140px;
    gap: unset;
  }

  .advantages__heading {
    margin-bottom: 48px;
  }

  .advantages__cta {
    margin-left: unset;
  }

  .advantages__wall {
    margin-bottom: 32px;
  }

  .flow {
    margin-bottom: 64px;
  }

  .flow__heading {
    margin-bottom: 68px;
    text-align: initial;
  }

  .flow__wandering-line {
    display: none;
  }

  .flow__content {
    margin-left: 20px;
  }

  .flow__line {
    margin-top: 8px;
  }

  .flow__list {
    padding-bottom: 40px;
  }

  .flow__item {
    padding-left: 40px;
  }

  .flow__marker {
    transform: translate(calc(-50% - 2px), 50%);
  }

  .flow__title {
    font-size: 16px;
  }

  .partners {
    margin-bottom: 112px;
  }

  .partners__heading {
    margin-bottom: 48px;
  }

  .partners__slider--first {
    margin-bottom: 10px;
  }

  .faq {
    padding-bottom: 88px;
  }

  .faq__decoration {
    height: 93px;
    width: 100px;
  }

  .faq__heading {
    margin-bottom: 72px;
    width: min-content;
    line-height: 31px;
  }

  .faq__inner-wrap {
    width: unset;
  }

  .faq__list {
    gap: 12px;
  }

  .faq__top {
    gap: 28px;
  }

  .faq__title {
    font-size: 16px;
  }

  .faq__text {
    font-size: 14px;
  }

  .rejection {
    margin-bottom: 92px;
  }

  .rejection__heading {
    margin-bottom: 48px;
  }

  .contact {
    margin-bottom: 265px;
  }

  .contact__lighter {
    transform: translate(-55%, -15%);
    width: 252px;
    height: 548px;
  }

  .contact__content {
    padding-left: unset;
  }

  .contact__heading {
    margin-bottom: 55px;
  }

  .services__lighter {
    filter: blur(670.5px);
    border-radius: 78px;
    width: 270px;
    height: 764px;
    transform: translate(75%, 2%);
  }

  .services__top {
    margin-bottom: 54px;
  }

  .services__heading {
    margin-bottom: 20px;
  }

  .services__list {
    gap: 12px;
  }

  .services__list--visible {
    margin-bottom: 12px;
  }

  .services__item {
    min-height: 76px;
  }

  .services__title {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .container {
    width: 345px;
  }

  .popup {
    min-width: unset;
    height: 100%;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }

  .header__nav {
    display: none;
  }

  .adv-card {
    flex: unset;
    width: 100%;
  }

  .about__container {
    display: flex;
    flex-direction: column;
  }

  .about {
    background-position: center 30%;
    background-size: 600px;
    margin-bottom: 152px;
  }

  .about__info {
    margin-bottom: 84px;
    text-align: right;
    order: 1;
  }

  .about__cta .button__icon {
    display: flex;
  }

  .about__cta {
    order: 2;
  }

  .rejection__wall {
    gap: 12px;
  }

  .contact__content {
    margin-bottom: 64px;
    flex-direction: column;
  }

  .contact__heading {
    margin-right: unset;
  }

  .services__button {
    padding: 0 72px;
    gap: 4px;
  }
}