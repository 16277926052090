.audience {
  display: flex;
  flex-direction: column;
  gap: 52px;
  margin-bottom: 116px;
  position: relative;

  @include max-width(tablet) {
    gap: 40px;
    margin-bottom: 148px;
    padding-top: 76px;
  }

  &__lighter {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-85%, -62%);

    @include max-width(tablet) {
      width: 790px;
      height: 660px;
      transform: translate(-40%, -200%);
    }
  }

  &__heading {
    display: none;

    @include max-width(tablet) {
      display: block;
    }
  }

  &__block {
    display: flex;
    gap: 160px;

    @include max-width(tablet) {
      flex-direction: column;
      gap: 12px;
    }
  }

  &__title {
    flex: 280px 0 0;
    line-height: 96.5%;

    @include max-width(tablet) {
      flex: unset;
    }
  }

  &__text {
    max-width: 700px;
  }

  &__list {
    font-family: Inter;
    max-width: 620px;
  }
}