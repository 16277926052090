.footer {
  padding-top: 54px;
  padding-bottom: 24px;
  background: $purple-40;

  @include max-width(tablet) {
    padding-top: 24px;
    padding-bottom: 48px;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    padding-bottom: 52px;
    border-bottom: 2px solid $grey-10;

    @include max-width(tablet) {
      flex-direction: column;
    }
  }
  &__aside {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include max-width(tablet) {
      flex-direction: row;
      justify-content: start;
      align-items: center;
      gap: 32px;
      margin-bottom: 60px;
    }
  }
  &__logo {
    width: 140px;
    height: 24px;
    display: flex;
  }

  &__messengers {
    display: flex;
    gap: 12px;
  }

  &__menues {
    display: flex;
    column-gap: 64px;

    @include max-width(tablet) {
      display: grid;
      grid-template-areas:
        "company info"
        "company contacts";
    }
  }

  &__menu {
    &:first-child {
      grid-area: company;
    }
    &:nth-child(2) {
      grid-area: info;
      @include max-width(tablet) {
        padding-bottom: 14px;
        border-bottom: 2px solid $grey-10;
        margin-bottom: 14px;
      }
    }
    &:last-child {
      grid-area: contacts;
    }
  }

  &__bottom {
    padding-top: 28px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 24px;

    @include max-width(tablet) {
      flex-direction: column;
      align-items: center;
      // justify-content: center;
      text-align: center;
      padding-top: 47px;
      row-gap: 32px;
    }
  }
  &__policy, &__agreement, &__copyright {
    opacity: .6;
  }
  &__agreement {
    margin-left: 172px;

    @include max-width(tablet) {
      margin-left: unset;
    }
  }

  &__copyright {
    display: flex;
    gap: 24px;
    width: 100%;

    @include max-width(tablet) {
      width: unset;
    }
  }
}
