.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #2e2e2ed5;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  transition: opacity .3s ease;
}