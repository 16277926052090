.popup {
  background: $purple-40;
  box-shadow: 11px 19px 25px rgba(154, 106, 255, 0.2);
  border-radius: 24px;
  padding: 110px 124px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 790px;
  text-align: center;
  display: flex;
  align-items: center;
  transition: opacity .3s ease;

  @include max-width(desktop) {
    padding: 100px 32px;
    min-width: 500px;
  }

  @include max-width(mobile) {
    min-width: unset;
    height: 100%;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }

  &__wrap {
    width: 100%;
  }

  &__close {
    cursor: pointer;
    position: absolute;
    top: 32px;
    right: 32px;
  }

  &__heading {
    margin-bottom: 30px;
  }

  &__hint {
    margin-bottom: 40px;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  &__submit {
    align-self: flex-end;
  }

}