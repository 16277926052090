.flow {
  position: relative;
  margin-bottom: 144px;

  @include max-width(tablet) {
    margin-bottom: 64px;
  }

  &__heading {
    text-align: center;
    margin-bottom: 136px;

    @include max-width(tablet) {
      margin-bottom: 68px;
      text-align: initial;
    }
  }

  &__wandering-line {
    display: flex;
    flex-direction: column;
    padding-bottom: 52px;
    position: absolute;
    left: 150px;
    bottom: 480px;

    @include max-width(desktop) {
      left: 20px;
    }
    @include max-width(tablet) {
      display: none;
    }

    .dot {
      &:first-child {
        margin-bottom: 388px;
      }
      &:nth-child(2) {
        margin-bottom: 42px;
      }
      &:nth-child(3) {
        margin-bottom: 244px;
      }
    }
  }

  &__content {
    display: flex;
    justify-content: flex-end;

    @include max-width(tablet) {
      margin-left: 20px;
    }
  }

  &__line {
    margin-top: 24px;

    @include max-width(tablet) {
      margin-top: 8px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 48px;
    max-width: 850px;
    padding-bottom: 72px;
    @include max-width(tablet) {
      padding-bottom: 40px;

    }
  }

  &__item {
    display: flex;
    padding-left: 80px;
    position: relative;
    cursor: default;
    @include max-width(tablet) {
      padding-left: 40px; 
    }
  }

  &__marker {
    position: absolute;
    left: 0;
    top: 8px;
    transform: translate(calc(-50% - 2px), 100%);
    transition: all 0.3s ease-in-out;
    @include max-width(tablet) {
      transform: translate(calc(-50% - 2px), 50%);
    }
  }

  // marker state on item title hover
  &__title {
    @include max-width(tablet) {
      font-size: 16px;
    }
  }

  &__title:hover ~ &__marker {
    transform: translate(calc(-50% - 2px), 50%) scale(2);
    box-shadow: 0px 4px 68px #9a6aff;
    background-color: $purple-10;
  }
}
