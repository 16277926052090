.rej-card {
  background-color: #ffffff0d;
  border-radius: 16px;
  padding: 24px;
  min-height: 204px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include max-width(tablet) {
  min-height: unset;
  }

  &__close {
    margin-bottom: 48px;
    display: block;

    @include max-width(tablet) {
      margin-bottom: 24px;
    }
  }

  &__title {
    line-height: 110%;
    margin-bottom: 12px;

    @include max-width(tablet) {
      font-size: 16px;
    }
  }

  &__text {
    line-height: 24px;
    max-width: 85%;
    @include max-width(tablet) {
      line-height: 150%;
    }
  }

  &__text {
    font-family: Inter;
  }
}
