.dot {
  transform: translateX(calc(-50% + 2px));
  background-color: $blue-10;
  border-radius: 9999px;
  width: 14px;
  height: 14px;
  display: block;
  &--purple {
    width: 28px;
    height: 28px;
    background: radial-gradient(50% 50% at 50% 50%, #996AFF 99.99%, rgba(255, 255, 255, 0) 100%);
  }
}
